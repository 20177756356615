
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class ExpectationIcon extends Vue {
	@Prop({ type: Boolean, required: false, default: false }) isActive!: boolean;
	// :fill="isActive ? '#FDFDFD' : '#717DC6'"
	// :fill="isActive ? '#343A60' : '#717DC6'"
	// :fill="isActive ? '#343A60' : '#FDFDFD'"
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        width: "17",
        height: "20",
        viewBox: "0 0 17 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M1.2622 0H14.7378C15.0857 0 15.4013 0.153029 15.6278 0.391073L15.6319 0.386823C15.8625 0.629118 16.004 0.96068 16.004 1.32625V18.6737C16.004 19.2689 15.6319 19.6004 15.6319 19.6089C15.4013 19.8512 15.0857 20 14.7378 20H1.2622C0.566372 20 0 19.4049 0 18.6737V1.3305C0 0.595112 0.574463 0 1.2622 0ZM3.00582 3.07758C2.75499 3.07758 2.55272 3.29012 2.55272 3.55367V6.6865C2.55272 6.95005 2.75499 7.16259 3.00582 7.16259H5.98736C6.23818 7.16259 6.44045 6.95005 6.44045 6.6865V3.55367C6.44045 3.29012 6.23818 3.07758 5.98736 3.07758H3.00582ZM3.00582 12.8459C2.75499 12.8459 2.55272 13.0584 2.55272 13.322V16.4506C2.55272 16.7141 2.75499 16.9267 3.00582 16.9267H5.98736C6.23818 16.9267 6.44045 16.7141 6.44045 16.4506V13.322C6.44045 13.0584 6.23818 12.8459 5.98736 12.8459H3.00582ZM8.13957 4C6.73982 4 6.73982 6.24017 8.13957 6.24017H12.4278C13.8276 6.24017 13.8276 4 12.4278 4H8.13957ZM8.13957 13.7641C6.73982 13.7641 6.73982 16.0042 8.13957 16.0042H12.4278C13.8276 16.0042 13.8276 13.7641 12.4278 13.7641H8.13957Z",
          fill: _vm.isActive ? "#717DC6" : "#FDFDFD",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8.13965 8.88416C6.73991 8.88416 6.73991 11.1243 8.13965 11.1243H12.4279C13.8276 11.1243 13.8276 8.88416 12.4279 8.88416H8.13965Z",
          fill: _vm.isActive ? "#343A60" : "#717DC6",
        },
      }),
      _c("path", {
        attrs: {
          d: "M2.62959 10.2657L3.62478 11.83C3.80278 12.1105 4.19924 12.1105 4.37725 11.8257L6.35955 8.69713C6.68723 8.17853 5.93477 7.65143 5.60708 8.17003L3.99697 10.712C3.59242 10.0744 3.26069 9.24973 2.75095 9.6068C2.54868 9.75133 2.49204 10.0489 2.62959 10.2657Z",
          fill: _vm.isActive ? "#343A60" : "#717DC6",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }